import React, {Component} from "react";
import Select from 'react-select';

export default (binder) =>{
    return {
        stationSelect: stationSelect.bind(binder),
        rangeSelect: rangeSelect.bind(binder)
    }
}


function stationSelect (config, stylePrefix) {
    stylePrefix = stylePrefix ? stylePrefix : "";

    return <Select className={stylePrefix + "stantion-select"}
                   value={this.state.station}
                   onChange={(selected) =>{
                       this.setState({ station: selected, data: null});
                       this.loadData(selected);
                   }}
                   options={config.points}
    />
}

function rangeSelect(config, data, _createChartData, stylePrefix) {
    stylePrefix = stylePrefix ? stylePrefix : "";
    return <Select className={stylePrefix + "since-select"}
                   value={this.state.since}
                   onChange={(selected) =>{
                       this.setState({
                           since: selected,
                           data:  {data: _createChartData(data, selected.value)}
                       });
                   }}
                   options={config.ranges}
    />

}