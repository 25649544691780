import React, {Component} from "react";
import {Route, Link, HashRouter} from "react-router-dom";
import Home from "./home";

export default class Main extends Component{
    render(){
        return (
<div>
            <Home/>
</div>
        );
    };
}
