import React, {Component} from "react";

const points = [

    {label:"Пловдив-СИ", value:"01", alt:167.0},
    {label:"Марково", value:"02", alt:412.0},
    {label:"Пловдив-център", value:"04", alt: 171.2},
    {label:"Куклен", value:"08", alt:308.0},
    {label:"Пловдив-И", value:"09", alt:182.0},
    {label:"Крумово", value:"10", alt: 	169.0},
    {label:"Пловдив-Тракия", value:"11", alt:165.0},
    {label:"Мирково", value:"22", alt: 676.2},
    {label:"Троян", value:"25", alt:399.3},
    {label:"София-Бизнес парк", value:"41", alt:662.1},
    {label:"Варвара", value:"29", alt:338.1},
    {label:"Хижа Плевен", value:"30", alt:1388.0},
    {label:"Хижа Здравец", value:"31", alt:1212.5},
    {label:"Велинград", value:"32", alt:759.6},
    {label:"Пловдив-СЗ", value:"35", alt: 192.0},
    {label:"Самоков", value:"36", alt:946.5},
    {label:"Мосомище", value:"37", alt:610.7},
    {label:"Велико Търново", value:"38", alt:189.0},
    {label:"Добрич", value:"39", alt:229.3},
    {label:"Сърнегор", value:"40", alt:408.3},
    {label:"София-Илинден", value:"42", alt:560.9},
    {label:"София-Хладилника", value:"43", alt:628.1},
    {label:"София-Яворов", value:"44", alt:548.5},
    {label:"София-Младост", value:"46", alt:608.6},
    {label:"Муселиево", value:"47", alt:33.7},
    {label:"Банско", value:"48", alt: 931.4},
    {label:"Софиа-Гео Милев", value:"208", alt: 571.0},
].sort( (x, y) => x.label < y.label);

const alt = id => {
    let station = stationById(id);
    let alt = null;
    if(station){
        alt = station.alt;
    }
    return alt ? alt : "N/A";
}

const pointsSorted = () =>{
    return points.sort((a, b) =>{
        return (a.label > b.label);
    });
}

const ranges = [
    {label: "1 ден", value: "day"},
    {label: "3 дни", value: "3days"},
    {label: "1 седмица", value: "week"},
    {label: "1 месец", value: "month"},
];

function stationById(id) {
    for(let i = 0; i < points.length; i++) {
        if(points[i].value == (id + "")){
            return points[i];
        }
    };
    return null;
}

const since = limit => {
    let date = new Date();
    switch (limit){
        case "day":
            date = date.setDate(date.getDate() - 1);
            break;
        case "3days":
            date = date.setDate(date.getDate() - 3);
            break;
        case "week":
            date = date.setDate(date.getDate() - 7);
            break;
        case "month":
            date = date.setMonth(date.getMonth() - 1);
            break;
        default:
            date = date.setYear(date.getYear() - 1);
    }
    return new Date(date);
};



export default {
    points: pointsSorted(),
    ranges: ranges,
    defaultStation: {label:"Мирково", value:"22"},
    defaultRange : {label: "3 дни", value: "3days"},
    stationById: stationById,
    since: since,
    alt: alt
}

