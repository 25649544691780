export default class DataSelector{

    constructor(){
        this.data = null;
        this.current = null;
    }

    setData(data){
        this.data = data;
    }

    getData(){
        return this.data;
    }

    setCurrent(current){
        this.current = current;
    }

    getCurrentTemp(){
        return (this.current && this.current.temp) ? this.current.temp : "N/A";
    }

    getCurrentPressure(){
        return (this.current && this.current.pressure) ? this.current.pressure : "N/A";
    }

    updated(){
        try{
            return format(this.current, "updated").toLocaleString("en-GB");
        }catch(e){
            return "N/A";
        }
    }

    min(){
        return format(this.current, "min");
    }
    max(){
        return format(this.current, "max");
    }

}

const format = (obj, prop) => {
    return (obj && obj[prop]) ? obj[prop] : "N/A";
}