import axios from 'axios';

const URL = stationId => {
    return "https://meter.ac/gs/nodes/N" + stationId + "/data.raw";
}

export default async stationId => {
    try{
        return await loadData(stationId);
    }catch (error){
        return  {error: error.message}
    };
}

async function loadData(stationId) {
    return await axios.get(URL(stationId));
}