import React, {Component} from "react";
import Loader from "./loader";
import { Chart } from "react-google-charts";
import config from "./config";
import ReactLoading from 'react-loading';
import DataSelector from "./dataSelector";
import _selects from "./parts/selects";
import {BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";
import Cookies from 'universal-cookie';

const coockies = new Cookies();
const STATION_COOCKIE = "station";

const jsonCoockie = (name, defaultValue) => {
    let value = coockies.get(name);
    return value ? value : defaultValue;
}

const saveJsonCoockie = (name, json)=> {
    coockies.set(name, JSON.stringify(json));
}

export default class Home extends Component{

    constructor(props){
        super(props);
        let defStation = jsonCoockie(STATION_COOCKIE, config.defaultStation);
        this.state = {
            station: defStation,
            since: config.defaultRange
        };
        this.loadData = loadData.bind(this);
        this.dataSelector = new DataSelector();
        createChartData = createChartData.bind(this);
        this.p_selects = _selects(this);
    }

    componentDidMount(){
        this.loadData(this.state.station);
    }

    render(){
        return (
            <div>
            <BrowserView>
                <div className="home">
                <div className="header">
                    {this.p_selects.stationSelect(config)}
                    {this.p_selects.rangeSelect(config, this.dataSelector.getData(), createChartData)}

                    <div className="current-info">
                        {headerIcon("assets/icons/temp2.png")}
                        <span className="main-display">{this.dataSelector.getCurrentTemp()}&#0176;</span><span className="second-display">C</span>
                        {headerIcon("assets/icons/barometer.png")}
                        <span className="main-display">{this.dataSelector.getCurrentPressure()}</span> <span className="second-display">hPa</span>
                        {headerIcon("assets/icons/alt.png")}
                        <span className="main-display">{config.alt(this.state.station.value)}</span>
                        {headerIcon("assets/icons/updated.png")}
                        <span className="second-display">{this.dataSelector.updated()}</span>
                    </div>
                </div>
                <div className="content">
                    <div className="content-left">
                        {data(this.state.data)}
                    </div>
                    <div className="content-right">
                        {mapImage(this.state.station.value)}
                        <div className="righr-panel-info">
                            <div className="tr">
                                <div className="td">

                                </div>
                            </div>
                            <div className="tr">
                                <div className="td">
                                    {headerIcon("assets/icons/temp2.png")}
                                    <span className="main-display">{this.dataSelector.getCurrentTemp()}&#0176;</span><span className="second-display-table">C</span>
                                </div>
                            </div>
                            <div className="tr">
                                <div className="td">
                                    {headerIcon("assets/icons/barometer.png")}
                                    <span className="main-display">{this.dataSelector.getCurrentPressure()}</span> <span className="second-display-table">hPa</span>
                                </div>
                            </div>
                        <div className="tr">
                                <div className="td">
                                    {headerIcon("assets/icons/alt.png")}
                                    <span className="main-display">{config.alt(this.state.station.value)}</span>
                                    <span className="second-display dist-left"></span>
                                </div>
                            </div>
                        </div>
                        <div className="tr">
                            <div className="td">
                                {headerIcon("assets/icons/updated.png")}
                                <span className="second-display-table">{this.dataSelector.updated()}</span>
                            </div>
                        </div>
                        <div className="tr">
                            <div className="td">
                                {headerIcon("assets/icons/cold.png")}
                                <span className="second-display-table"><b>{minMaxValue(this.dataSelector.min())}&#0176;</b>C <b>*</b> {minMaxDate(this.dataSelector.min())} </span>
                            </div>
                        </div>
                        <div className="tr">
                            <div className="td">
                                {headerIcon("assets/icons/hot.png")}
                                <span className="second-display-table"> <b>{minMaxValue(this.dataSelector.max())}&#0176;</b>C  <b>*</b> {minMaxDate(this.dataSelector.max())} </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </BrowserView>
            <MobileView>
                <div className="m_home">
                    <div className="m_header">
                        {this.p_selects.stationSelect(config, "m_")}
                        {this.p_selects.rangeSelect(config, this.dataSelector.getData(), createChartData, "m_")}

                    </div>
                    <div className="m_content">
                        <div className="righr-panel-info">
                            <div className="tr">
                                <div className="td">

                                </div>
                            </div>
                            <div className="tr">
                                <div className="td">
                                    {headerIcon("assets/icons/temp2.png")}
                                    <span className="main-display">{this.dataSelector.getCurrentTemp()}&#0176;</span><span className="second-display-table">C</span>
                                </div>
                            </div>
                            <div className="tr">
                                <div className="td">
                                    {headerIcon("assets/icons/barometer.png")}
                                    <span className="main-display">{this.dataSelector.getCurrentPressure()}</span> <span className="second-display-table">hPa</span>
                                </div>
                            </div>
                            <div className="tr">
                                <div className="td">
                                    {headerIcon("assets/icons/alt.png")}
                                    <span className="main-display">{config.alt(this.state.station.value)}</span>
                                    <span className="second-display dist-left"></span>
                                </div>
                            </div>
                        </div>
                        <div className="tr">
                            <div className="td">
                                {headerIcon("assets/icons/updated.png")}
                                <span className="second-display-table">{this.dataSelector.updated()}</span>
                            </div>
                        </div>
                        <div className="tr">
                            <div className="td">
                                {headerIcon("assets/icons/cold.png")}
                                <span className="second-display-table"><b>{minMaxValue(this.dataSelector.min())}&#0176;</b>C <b>*</b> {minMaxDate(this.dataSelector.min())} </span>
                            </div>
                        </div>
                        <div className="tr">
                            <div className="td">
                                {headerIcon("assets/icons/hot.png")}
                                <span className="second-display-table"> <b>{minMaxValue(this.dataSelector.max())}&#0176;</b>C  <b>*</b> {minMaxDate(this.dataSelector.max())} </span>
                            </div>
                        </div>

                            {data(this.state.data)}


                            {mapImage(this.state.station.value, "m_")}


                    </div>
                </div>
            </MobileView>
            </div>
        );
    };
}

const minMaxValue = obj =>{
    if(obj){
        return obj.value;
    }
    return "N/A";
}

const minMaxDate = obj =>{
    if(obj && obj.date & !(obj == "N/A")){
        return obj.date.toLocaleString("en-GB");
    }
    return "N/A";
}


const mapImage = (id, stylePrefix) => {
    stylePrefix = stylePrefix ? stylePrefix : "";
    let src = "assets/icons/" + id + ".jpg";
    return <img src={src} className={stylePrefix + "map-icon"} />
}

const headerIcon = src => {
    return <img src={src} alt="preview" className="header-icon" />
}

async function loadData(station) {
    this.dataSelector.setCurrent(null);
    let data = await Loader(station.value);
    if(data.data){
        this.dataSelector.setData(data.data.split(/\r?\n/));
        data.data = createChartData(this.dataSelector.getData(), this.state.since.value);
        if(data.data.temp.length == 1){
            data.data = null;
        }
    }

    this.setState({data: data});
    saveJsonCoockie(STATION_COOCKIE, station);
}


const data = data => {

    if(!data){
        return <ReactLoading type="spin" color="gray" height={100} width={100} className="loading"/>
    }

    if(data.error){
        return <p>Error occured: {data.error}</p>
    }else if(data.data){
        return(
            <div>
                {chart(data.data.temp, ["red"])}
                {chart(data.data.pressure, ["blue"])}
            </div>);
    }
}


const calcMin = (o, obj) => {
    if(!(o.date)){
        return obj;
    }
    return  (o.value > obj.value) ? obj : o;
}

const calcMax = (o, obj) => {
    if(!(o.date)){
        return obj;
    }
    return  (o.value < obj.value) ? obj : o;
}


function createChartData(lineArray, sinceValue) {
    let tempData = [["Time", "Temperature"]];
    let presureData = [["Time", "Pressure"]];
    let i = 0;
    let since = config.since(sinceValue);
    let lastValidDate = null;
    let min = {};
    let max = [];

    const tempIndex = 12;
    for(; i < lineArray.length; i ++){
       let arr = lineArray[i].split(" ");
       if(arr.length > 1){
           let date = getDate(arr);
           if(date && (date >= since) && arr[tempIndex] && arr[11]) {
                lastValidDate = date;
                let temp = parseFloat(arr[tempIndex]);
               let temperatureRow = [date, temp];
               let presureRow = [date, parseFloat(arr[11])];
               tempData.push(temperatureRow);
               presureData.push(presureRow);

               min = calcMin(min, {date: new Date(date.getTime()), value: temp})
               max = calcMax(max, {date: new Date(date.getTime()), value: temp})
           }
       }
    };

    this.dataSelector.setCurrent(
        {temp: tempData[tempData.length -1][1],
            pressure: presureData[presureData.length-1][1],
            updated: lastValidDate, min: min, max: max
        });

    return {temp: tempData, pressure: presureData};
}

function getDate(arr) {
        let date =  new Date(parseInt(arr[16]) * 1000);
        if(isDate(date)){
            return date;
        }
        date = new Date(parseInt(arr[17]) * 1000);
        if(isDate(date)){
            return date;
        }else{
            console.log("can't find date in: ", arr);
            throw ("can't find date in: ", arr);
        }

}

function isDate(date) {
    return date && !(date.toDateString() === "Invalid Date");
}

const chart = (data, colors) => {
    return (
        <Chart
            chartType="LineChart"
            data={data}
            width="100%"
            height="400px"
            legendToggle
            options={
                // Chart options
                {
                   colors: colors,
                    legend: { position: 'bottom' }
                }
            }
            className="chart"
        />
    );
}